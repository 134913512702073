import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Navbar from '../components/Navbar';
import Seo from '../components/Seo';

const NotFoundPage = () => {
   return (
      <>
         {' '}
         <Seo title='NYC Catering' />
         <Navbar />
         <header className='w-full'>
            <div className='relative'>
               <div className='absolute inset-x-0' />
               <div className='w-screen h-screen'>
                  <div className='relative h-full'>
                     <div className='absolute inset-0'>
                        <StaticImage
                           src='../images/Homepage-Just-A-Nibble-Hero.jpg'
                           quality='100'
                           className='h-full w-full object-cover'
                           alt='Just a Nibble Charcuterie board'
                        />
                        <div aria-hidden='true' className='opacity-40 absolute inset-0 bg-black' />
                     </div>
                     <div className='relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-24 '>
                        <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                           <span className='block header'>This Page </span>
                           <span className='block header'>Doesn't Exist!</span>
                        </h1>
                        <hr className='border-brand border rounded w-1/2 mx-auto my-3' />
                        <p className='text-white text-base md:text-xl uppercase tagline'>404</p>
                        <div className='mt-12'>
                           <Link
                              to='/'
                              className='proxima uppercase text-base hover:bg-white font-bold border-2 z-50 border-brand md:px-8 md:py-4 py-2 px-4 my-6 tracking-widest text-brand'
                           >
                              Well, now what?
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>
      </>
   );
};

export default NotFoundPage;
